/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx, Flex } from 'theme-ui';
import { ButtonTab, ButtonTabGroup } from '@verso/components';
// import ThinkIcon from '@verso/components/lib/icons/think.svg';
import ChalkboardTeacherIcon from '@fortawesome/fontawesome-free/svgs/solid/chalkboard-teacher.svg';
// import ChildIcon from '@fortawesome/fontawesome-free/svgs/solid/child.svg';
import UserFriendsIcon from '@fortawesome/fontawesome-free/svgs/solid/user-friends.svg';

const AppNav = ({
  selectedItemId,
  onClickItem,
  // newActivityCount,
  ...props
}) => {
  return (
    <Flex {...props} sx={{ pt: 3, justifyContent: 'center', px: [3, 4] }}>
      <ButtonTabGroup
        activeTabId={selectedItemId}
        sx={{
          width: '100%',
          justifyContent: 'center',
          '.Tab': { maxWidth: 9 },
        }}
      >
        <ButtonTab
          id="engagement"
          icon={ChalkboardTeacherIcon}
          onClick={() => onClickItem('engagement')}
          sx={{ width: '100%' }}
        >
          Engagement
        </ButtonTab>
        <ButtonTab
          id="community"
          icon={UserFriendsIcon}
          onClick={() => onClickItem('community')}
          sx={{ width: '100%' }}
        >
          Community
        </ButtonTab>
        {/* XXX HIDING FOR NOW WHILE PAGES STILL GET DEVELOPED */}
        {/* <ButtonTab
          id="reflections"
          count={newActivityCount}
          icon={ThinkIcon}
          onClick={() => onClickItem('reflections')}
          sx={{ width: '100%', '& svg': { padding: 0 } }}
        >
          Reflections
        </ButtonTab>
        <ButtonTab
          id="impact"
          icon={ChildIcon}
          onClick={() => onClickItem('impact')}
          sx={{ width: '100%' }}
        >
          Impact
        </ButtonTab> */}
      </ButtonTabGroup>
    </Flex>
  );
};

AppNav.propTypes = {
  selectedItemId: PropTypes.string,
  onClickItem: PropTypes.func.isRequired,
  newActivityCount: PropTypes.number,
};

AppNav.defaultProps = {
  selectedItemId: null,
};

export default AppNav;
