/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { Heading, FlexCol, FlexColItem, Image } from '@verso/components';

const ErrorContentTemplate = ({ title, children, ...props }) => {
  return (
    <FlexCol {...props} sx={{ justifyContent: 'center' }}>
      <FlexColItem cols={[4, 6, 8]}>
        <div
          sx={{
            color: 'textWhite',
          }}
        >
          <Flex
            sx={{
              maxWidth: ['none', 13],
              flexDirection: 'column',
              alignItems: ['center', 'flex-start'],
              textAlign: ['center', 'left'],
            }}
          >
            <Image
              sx={{
                maxHeight: [9, '164px'],
              }}
              // TODO : Update image.
              src={'https://media.versoapp.com/Brand/Verso/Light.svg'}
              alt={`Verso Campus logo`}
            />
            {title && (
              <Heading
                as="h1"
                sx={{
                  variant: [
                    'textStyles.heading5',
                    'textStyles.heading4',
                    'textStyles.heading3',
                  ],
                  mb: 3,
                }}
              >
                {title}
              </Heading>
            )}
            <hr
              sx={{
                width: 10,
                mx: 0,
                borderStyle: 'solid',
                borderWidth: 'thin',
                borderColor: 'textWhite',
              }}
            />
            {children}
          </Flex>
        </div>
      </FlexColItem>
    </FlexCol>
  );
};

ErrorContentTemplate.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  backgroundUrl: PropTypes.string,
};

export default ErrorContentTemplate;
