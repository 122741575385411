/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useQueryParams, ArrayParam } from 'use-query-params';
import { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useVersoAPI } from '../../hooks/useVersoAPI';
import GraphQLErrorCapture from '../GraphQLErrorCapture';
import Engagement from './Engagement';

const EngagementContainer = () => {
  const [query] = useQueryParams({
    domains: ArrayParam,
    years: ArrayParam,
    focus: ArrayParam,
  });

  // local state for now, could be on the route
  const [activeFilter, setActiveFilter] = useState('research');

  useEffect(() => {
    mixpanel.track('Filter Engagement Graph', {
      'Active Filter': activeFilter,
    });
  }, [activeFilter]);

  const { loading, cacheValue = {} } = useVersoAPI({
    loadOnMount: true,
    operation: {
      query: /* GraphQL */ `
        query Engagement(
          $filter: CampusFilterInput
          $type: String!
          $timePeriod: Int
        ) {
          currentTeacher {
            school {
              campusEngagementGraph(
                filter: $filter
                type: $type
                timePeriod: $timePeriod
              ) {
                date
                lineIdAll
                lineIdActive
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          focus: query.focus,
          domains: query.domains,
          years: query.years,
        },
        type: activeFilter,
        timePeriod: 365,
      },
    },
  });

  return (
    <GraphQLErrorCapture
      cacheValue={cacheValue}
      errorMessage={{
        title: 'This chart is currently unavailable.',
        subtitle:
          'Please refresh the page or return at a later time. If this issue persists, please contact our support team.',
      }}
    >
      <Engagement
        engagementChartData={
          cacheValue.data &&
          cacheValue.data.currentTeacher.school.campusEngagementGraph
        }
        activeFilter={activeFilter}
        onFilterSelect={setActiveFilter}
        loading={!cacheValue.data && loading}
      />
    </GraphQLErrorCapture>
  );
};

export default EngagementContainer;
