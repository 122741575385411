/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import {
  CardContent,
  EventFeedItemReflection,
  Paragraph,
  Button,
  Heading,
  Spinner,
} from '@verso/components';
import { Fragment } from 'react';

const Reflections = ({
  feedItems,
  userAvatarUrl,
  numberOfTotalFeedItems,
  onClickShowMoreFeed,
  loading,
}) => {
  return (
    <CardContent sx={{ mt: [3, 4, 4, 5] }}>
      <Paragraph sx={{ textAlign: 'center', mb: 1 }}>
        What are teachers discovering when reflecting on their practice?
      </Paragraph>
      <Paragraph
        sx={{
          variant: 'textStyles.caption',
          textAlign: 'center',
          color: 'textMediumEmphasis',
          mt: 1,
        }}
      >
        Showing {feedItems.length} of {numberOfTotalFeedItems} reflections
      </Paragraph>
      {loading ? (
        <Flex sx={{ my: 6, justifyContent: 'center' }}>
          <Spinner />
        </Flex>
      ) : (
        <div>
          {feedItems.map((item, index) => (
            <Fragment key={item.id}>
              <div sx={{ my: 4, width: '100%', display: 'block' }}>
                <EventFeedItemReflection
                  {...item}
                  userAvatarUrl={userAvatarUrl}
                  // XXX NEED TO WORK OUT HOW TO HANDLE ALL THESE FORMS
                  // postCommentFormId={itemProps.id}
                  // postCommentFormValue={}
                  // postCommentFormValidationMessage={itemProps}
                  // submittingPostComment={}
                  // onChangePostComment={onChangePostComment}
                  // onClickSubmitPostComment={onClickSubmitPostComment}
                />
              </div>
              {index !== feedItems.length - 1 && (
                <Flex sx={{ justifyContent: 'center' }}>
                  <div
                    sx={{
                      width: 13,
                      borderBottomStyle: 'solid',
                      borderBottomColor: 'neutral.1',
                      borderBottomWidth: 'thin',
                    }}
                  />
                </Flex>
              )}
            </Fragment>
          ))}
          {feedItems.length < numberOfTotalFeedItems ? (
            <Flex sx={{ justifyContent: 'center', mt: 5 }}>
              <Button onClick={onClickShowMoreFeed} variant="text">
                Show more
              </Button>
            </Flex>
          ) : (
            <div sx={{ textAlign: 'center', mt: 6 }}>
              <Heading as="h2" variant="heading2">
                Bonjour!
              </Heading>
              <Paragraph sx={{ mt: 0 }}>
                This is the start of your Verso timeline.
              </Paragraph>
            </div>
          )}
        </div>
      )}
    </CardContent>
  );
};

Reflections.propTypes = {
  numberOfTotalFeedItems: PropTypes.number,
  userAvatarUrl: PropTypes.string,
  onClickShowMoreFeed: PropTypes.func,
  feedItems: PropTypes.arrayOf({}),
  loading: PropTypes.bool,
};

Reflections.defaultProps = {
  feedItems: [],
  loading: false,
  numberOfTotalFeedItems: 0,
};

export default Reflections;
