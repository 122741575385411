/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardContent,
  FlexCol,
  FlexColItem,
} from '@verso/components';
import { useBreakpointIndex } from '@theme-ui/match-media';
import ContextComponents from '../lib/context-components';

const ContentTemplate = ({ children, ...props }) => {
  const breakpointIndex = useBreakpointIndex();
  return (
    <ContextComponents.Consumer>
      {({ AppNav, Filters }) => (
        <FlexCol {...props} gutter={4} margin={0} sx={{ mb: 4 }}>
          <FlexColItem cols={[12, 12, 12, 8]}>
            <Card sx={{ boxShadow: 4, width: '100%' }}>
              <AppNav />
              {children}
            </Card>
          </FlexColItem>
          {breakpointIndex >= 3 && (
            <FlexColItem cols={[8, 8, 4]}>
              <Card sx={{ boxShadow: 4, width: '100%' }}>
                <CardHeader>
                  <CardHeaderTitle title="Filter results" />
                </CardHeader>
                <CardContent>
                  <Filters />
                </CardContent>
              </Card>
            </FlexColItem>
          )}
        </FlexCol>
      )}
    </ContextComponents.Consumer>
  );
};

ContentTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentTemplate;
