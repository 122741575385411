/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useQueryParams, ArrayParam } from 'use-query-params';
import { useMemo } from 'react';
import { useVersoAPI } from '../../hooks/useVersoAPI';
import GraphQLErrorCapture from '../GraphQLErrorCapture';
import { get } from '../../lib/utils';
import Community from './Community';

const CommunityContainer = () => {
  const [query] = useQueryParams({
    domains: ArrayParam,
    years: ArrayParam,
    focus: ArrayParam,
  });

  const { loading, cacheValue = {} } = useVersoAPI({
    loadOnMount: true,
    operation: {
      query: /* GraphQL */ `
        query Community($filter: CampusFilterInput) {
          currentTeacher {
            school {
              campusNetworkGraph(filter: $filter) {
                _id
                value
                role
                invitedTeachers {
                  relationship
                  teacherId
                  status
                }
                label
                tooltip
                linkConfigs
                link
                subjects
                years
              }
            }
          }
        }
      `,
      variables: {
        filter: {
          focus: query.focus,
          domains: query.domains,
          years: query.years,
        },
      },
    },
  });

  const communityData = useMemo(() => {
    const campusNetworkGraph =
      get(cacheValue, 'data.currentTeacher.school.campusNetworkGraph') || [];
    return campusNetworkGraph.map(focus => ({
      _id: focus._id,
      value: focus.value,
      role: focus.role,
      invitedTeachers: focus.invitedTeachers,
      label: focus.label,
      tooltip: `${focus.tooltip}\r\n${focus.role}`,
      linkConfigs: focus.linkConfigs,
      link: focus.link,
      subjects: focus.subjects,
      years: focus.years,
    }));
  }, [cacheValue]);

  return (
    <GraphQLErrorCapture
      cacheValue={cacheValue}
      errorMessage={{
        title: 'This chart is currently unavailable.',
        subtitle:
          'Please refresh the page or return at a later time. If this issue persists, please contact our support team.',
      }}
    >
      <Community
        communityChartData={communityData}
        loading={!cacheValue.data && loading}
      />
    </GraphQLErrorCapture>
  );
};

export default CommunityContainer;
