export default [
  {
    id: 'School Leadership',
    color: 'charts.data.0',
    label: 'School leadership',
  },
  {
    id: 'Leading Teacher',
    color: 'charts.data.2',
    label: 'Leading teacher',
  },
  {
    id: 'Learning Specialist',
    color: 'charts.data.4',
    label: 'Learning specialist',
  },
  {
    id: 'Classroom Teacher',
    color: 'charts.data.6',
    label: 'Classroom teacher',
  },
  {
    id: 'Early Career Teacher (2 - 5 years)',
    color: 'charts.data.8',
    label: 'Early career teacher',
  },
  {
    id: 'Graduate Teacher (less than 1 year)',
    color: 'charts.data.10',
    label: 'Graduate teacher',
  },
];
