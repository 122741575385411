/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CardContent, Paragraph } from '@verso/components';
import { memo } from 'react';
import PropTypes from 'prop-types';
import EngagementLineChart, {
  EngagementLineChartPropTypes,
} from '../EngagementLineChart';
import EngagementFilters from '../EngagementFilters';

const Engagement = ({
  activeFilter,
  onFilterSelect,
  engagementChartData,
  loading,
}) => {
  return (
    <CardContent sx={{ mt: [3, 4, 4, 5] }}>
      <Paragraph sx={{ textAlign: 'center', mb: 0 }}>
        {activeFilter === 'research' &&
          'How many teachers undertook professional research?'}
        {activeFilter === 'activity' &&
          'How many teachers engaged students in deep learning?'}
        {activeFilter === 'feedback' &&
          'How many teachers collected feedback from students?'}
        {activeFilter === 'reflection' &&
          'How many teachers reflected on their practice?'}
        {activeFilter === 'discussion' &&
          'How many teachers discussed their practice or the practice of a colleague?'}
      </Paragraph>
      <EngagementLineChart
        data={engagementChartData}
        activeFilter={activeFilter}
        loading={loading}
      />
      <EngagementFilters
        activeFilter={activeFilter}
        onFilterSelect={onFilterSelect}
      />
    </CardContent>
  );
};

Engagement.propTypes = {
  activeFilter: EngagementLineChartPropTypes.activeFilter,
  onFilterSelect: PropTypes.func,
  engagementChartData: EngagementLineChartPropTypes.data,
  loading: EngagementLineChartPropTypes.loading,
};

export default memo(Engagement);
