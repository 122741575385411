/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { FooterSimple } from '@verso/components';
import footerLinks from './common/footer-links';

const PageSimpleTemplate = ({ backgroundUrl, children, ...props }) => {
  return (
    <Flex
      {...props}
      sx={{
        position: 'relative',
        bg: 'neutral.0',
        minHeight: '100vh',
        flexDirection: 'column',
        backgroundImage: `url('${backgroundUrl}')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        justifyContent: 'space-between',

        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bg: 'primaryDark',
          opacity: 0.6,
        },
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          zIndex: 1,
          p: [4, 5],
          pt: theme => [4, theme.sizes[8]],
          width: '100%',
          flexDirection: 'column',
          flex: '1 1 auto',
        }}
      >
        {children}
      </Flex>
      <FooterSimple
        links={footerLinks}
        sx={{ position: 'relative', zIndex: 1 }}
      />
    </Flex>
  );
};

PageSimpleTemplate.propTypes = {
  backgroundUrl: PropTypes.string,
  children: PropTypes.node,
};

export default PageSimpleTemplate;
