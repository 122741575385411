/** @jsx jsx */
import { Paragraph } from '@verso/components';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

const SectionError = ({ title, subtitle }) => {
  return (
    <div>
      <Paragraph
        sx={{
          color: 'alerts.error',
          textAlign: 'center',
        }}
      >
        {title}
      </Paragraph>
      <Paragraph
        sx={{
          color: 'textMediumEmphasis',
          textAlign: 'center',
        }}
      >
        {subtitle}
      </Paragraph>
    </div>
  );
};

SectionError.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SectionError.defaultProps = {
  title: 'This page is currently unavailable',
};

export default SectionError;
