/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Spinner } from '@verso/components';

const PageTemplateLoading = () => {
  return (
    <Spinner
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
    />
  );
};

export default PageTemplateLoading;
