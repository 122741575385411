/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import SectionError from './SectionError';

// https://github.com/jaydenseric/graphql-react#type-graphqlcachevalue
const errorsTypes = ['fetchError', 'httpError', 'parseError', 'graphQLErrors'];

const GraphQLErrorCapture = ({
  cacheValue,
  children,
  hasOwnError,
  errorMessage,
}) => {
  const hasErrors = Object.keys(cacheValue).some(error =>
    errorsTypes.includes(error),
  );

  useEffect(() => {
    // perhaps dig out and report errors to mixpanel, etc
  });
  return hasErrors && !hasOwnError ? (
    <SectionError title={errorMessage.title} subtitle={errorMessage.subtitle} />
  ) : (
    children
  );
};

GraphQLErrorCapture.propTypes = {
  cacheValue: PropTypes.object,
  children: PropTypes.node,
  hasOwnError: PropTypes.bool,
  errorMessage: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};

GraphQLErrorCapture.defaultProps = {
  errorMessage: {},
};

export default GraphQLErrorCapture;
