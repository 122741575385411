/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Paragraph, Button } from '@verso/components';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import PageSimpleTemplate from '../PageSimpleTemplate';
import ErrorContentTemplate from '../ErrorContentTemplate';

const Unauthorised = ({ onClickLogout, ...props }) => {
  return (
    <PageSimpleTemplate
      {...props}
      backgroundUrl="https://media.versoapp.com/Images/backgrounds/teacher-class.jpg"
    >
      <ErrorContentTemplate title="Sorry, your account isn’t authorised to access Verso Campus.">
        <Paragraph>
          Try accessing it via the dashboard, otherwise please{' '}
          <a
            href="https://support.versoapp.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ textDecoration: 'underline', color: 'textWhite' }}
          >
            get in touch
          </a>{' '}
          if this is incorrect or you would like to request access.
        </Paragraph>
        <div>
          <Button
            variant="outlined"
            sx={{
              borderColor: 'textWhite',
              color: 'textWhite',
              bg: 'transparent',
              mr: 2,
            }}
            onClick={() => {
              navigate('https://v2.versoapp.com');
            }}
          >
            Go to Verso
          </Button>
          <Button
            variant="text"
            sx={{
              color: 'textWhite',
              bg: 'transparent',
            }}
            onClick={onClickLogout}
          >
            logout
          </Button>
        </div>
      </ErrorContentTemplate>
    </PageSimpleTemplate>
  );
};

Unauthorised.propTypes = {
  onClickLogout: PropTypes.func.isRequired,
};

export default Unauthorised;
