import PropTypes from 'prop-types';
import React from 'react';
import { versoFetchOptionsOverride } from '../../lib/graphql';
import useCampusForm from '../../hooks/useCampusForm';
import Login from './Login';

const LoginContainer = ({ onLogin }) => {
  const {
    formState,
    onChange,
    onSubmit,
    validationMessages,
    isSubmitting,
  } = useCampusForm({
    initialState: {
      user: '',
      password: '',
    },
    fetchOptionsOverride: versoFetchOptionsOverride,
    resetOnLoad: true,
    onSuccess(payload) {
      localStorage.setItem('Meteor.loginToken', payload.user.loginToken);
      localStorage.setItem(
        'Meteor.loginTokenExpires',
        payload.user.loginTokenExpires,
      );
      onLogin();
    },
    onFailure(message) {
      alert(message);
    },
    operationName: 'login',
    operation() {
      return {
        query: /* GraphQL */ `
          mutation login($user: String!, $password: String!) {
            login(user: $user, password: $password) {
              success
              error
              errors {
                message
                field
              }
              user {
                loginToken
                loginTokenExpires
              }
            }
          }
        `,
        variables: {
          user: formState.user,
          password: formState.password,
        },
      };
    },
  });

  return (
    <Login
      validationMessages={validationMessages}
      formState={formState}
      onChange={onChange}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    />
  );
};

LoginContainer.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default LoginContainer;
