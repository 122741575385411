import { Router, globalHistory } from '@reach/router';
import { GraphQL, GraphQLProvider } from 'graphql-react';
import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import ThemeProvider from '../lib/ThemeProvider';
import { AuthProvider } from '../lib/AuthProvider';
import Layout from './LayoutContainer';
import PageNotFound from './pages/PageNotFound';
import Campus from './CampusContainer';
import ErrorBoundary from './ErrorBoundary';

const graphql = new GraphQL();

const App = () => {
  return (
    <ThemeProvider>
      <GraphQLProvider graphql={graphql}>
        <AuthProvider>
          <QueryParamProvider reachHistory={globalHistory}>
            <ErrorBoundary>
              <Router>
                <Layout as={Campus} path="/" />
                <PageNotFound default />
              </Router>
            </ErrorBoundary>
          </QueryParamProvider>
        </AuthProvider>
      </GraphQLProvider>
    </ThemeProvider>
  );
};

export default App;
