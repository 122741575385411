/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useVersoAPI } from '../../hooks/useVersoAPI';
import GraphQLErrorCapture from '../GraphQLErrorCapture';
import Impact from './Impact';

const ImpactContainer = () => {
  const { loading, cacheValue = {} } = useVersoAPI({
    loadOnMount: true,
    operation: {
      query: /* GraphQL */ `
        query Impact_currentTeacher {
          currentTeacher {
            fullName
          }
        }
      `,
    },
  });

  return (
    <GraphQLErrorCapture cacheValue={cacheValue}>
      <Impact
        fullName={
          cacheValue.data ? cacheValue.data.currentTeacher.fullName : ''
        }
        // XXX: Needs populating.
        // impactChartData={}
        // impactChartKey={}
        loading={!cacheValue.data && loading}
      />
    </GraphQLErrorCapture>
  );
};

export default ImpactContainer;
