/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useQueryParams, StringParam } from 'use-query-params';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import EngagementContainer from './pages/EngagementContainer';
import CommunityContainer from './pages/CommunityContainer';
import ReflectionsContainer from './pages/ReflectionsContainer';
import ImpactContainer from './pages/ImpactContainer';

const CampusContainer = () => {
  const [query] = useQueryParams({
    view: StringParam,
  });

  const { view } = query;

  useEffect(() => {
    mixpanel.track('Page View', {
      'Page Path': location.pathname,
      View: view,
    });
  }, [view]);

  const views = {
    engagement: <EngagementContainer />,
    community: <CommunityContainer />,
    reflections: <ReflectionsContainer />,
    impact: <ImpactContainer />,
  };

  return views[view] || views.engagement;
};

export default CampusContainer;
