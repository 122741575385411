/** @jsx jsx */
import { ChartKeyItem, ForceDirectedTreeChart } from '@verso/components';
import PropTypes from 'prop-types';
import { memo, Fragment, useMemo, useState, useCallback, useRef } from 'react';
import { jsx, Flex, useThemeUI } from 'theme-ui';
import userRoles from './common/user-roles';
import ChartLoader from './ChartLoader';

// Will attempt to find the theme color. If fails, returns the passed in color.
const convertThemeColorToHex = (theme, color) => {
  const valueAtObjPath = (obj, path, level = 0) => {
    const pathParts = color.split('.');

    if (pathParts.length - 1 === level) {
      return obj[pathParts[level]];
    } else {
      return valueAtObjPath(obj[pathParts[level]], path, level + 1);
    }
  };
  const themeColour = valueAtObjPath(theme.colors, color);
  return themeColour ? themeColour : color;
};

const CommunityConnectionsChart = ({ chartId, data, loading, ...props }) => {
  const context = useThemeUI();
  const themeRef = useRef(context.theme);

  const [zoomLevel, setZoomLevel] = useState(0.5);

  const onChangeZoomLevel = useCallback(val => setZoomLevel(val), []);

  const config = useMemo(
    () => ({
      zoomable: true,
      zoomResetTo: 0.5,
      minBaseRadius: 16,
      maxBaseRadius: 40,
    }),
    [],
  );

  // Updated data array to include role color.
  const mutatedData = useMemo(() => {
    const theme = themeRef.current;
    if (data) {
      return data.map(dataItem => {
        const newDataItem = { ...dataItem };
        if (!dataItem.role) {
          return dataItem;
        }

        const roleData = userRoles.find(
          role => role.id.toLowerCase() === dataItem.role.toLowerCase(),
        );

        newDataItem.color = roleData
          ? convertThemeColorToHex(theme, roleData.color)
          : convertThemeColorToHex(theme, 'neutral.1');
        return newDataItem;
      });
    }
  }, [data]);

  return (
    <Fragment>
      <div sx={{ position: 'relative' }}>
        <ForceDirectedTreeChart
          {...props}
          chartId={chartId}
          data={mutatedData}
          config={config}
          zoomPercentage={zoomLevel}
          onZoomChange={onChangeZoomLevel}
        />
        {loading && <ChartLoader />}
      </div>

      {userRoles.length > 0 && (
        <Flex sx={{ justifyContent: 'center', flexWrap: 'wrap' }}>
          {userRoles.map(keyItem => (
            <ChartKeyItem
              key={keyItem.id}
              color={keyItem.color}
              sx={{ mx: 2, flexShrink: 0, mt: 2 }}
              variant="square"
            >
              {keyItem.id}
            </ChartKeyItem>
          ))}
        </Flex>
      )}
    </Fragment>
  );
};

export const CommunityConnectionsChartPropTypes = {
  data: PropTypes.array,
  chartId: PropTypes.string,
  loading: PropTypes.bool,
};

CommunityConnectionsChart.propTypes = CommunityConnectionsChartPropTypes;

CommunityConnectionsChart.defaultProps = {
  chartId: 'impact-tree-chart',
  data: [],
  loading: false,
};
export default memo(CommunityConnectionsChart);
