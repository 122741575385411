import { ThemeProvider as ThemeProviderVerso } from '@verso/components';
import React from 'react';
import theme from '@verso/components/lib/themes/verso/theme';
import PropTypes from 'prop-types';
import GlobalStyles from './GlobalStyles';

const ThemeProvider = ({ children }) => {
  return (
    <ThemeProviderVerso theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProviderVerso>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
