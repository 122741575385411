import { useContext } from 'react';
import { GraphQLContext } from 'graphql-react';
import useForm from '@verso/components/lib/hooks/useForm';

// A form handling abstraction that ties together a structured GraphQL mutation response,
// our useForm hook and validation messaging UI, and a graphql-react query.
const useCampusForm = ({
  operation,
  operationName,
  initialState,
  onSuccess,
  onFailure,
  resetOnLoad,
  fetchOptionsOverride,
}) => {
  const graphql = useContext(GraphQLContext);

  const {
    validationMessages,
    setValidationMessages,
    formState,
    onChange,
    isSubmitting,
    setIsSubmitting,
    onSubmit,
  } = useForm({
    initialState,
    async onSubmit() {
      setIsSubmitting(true);

      const request = graphql.operate({
        fetchOptionsOverride,
        resetOnLoad,
        operation: operation(),
      });

      const cache = await request.cacheValuePromise;
      const payload = cache.data[operationName];

      setIsSubmitting(false);

      if (cache.fetchError) {
        return onFailure(cache.fetchError);
      }

      if (payload.error) {
        return onFailure(payload.error);
      }

      if (payload.errors) {
        const formErrors = {};
        payload.errors.forEach(
          ({ field, message }) => (formErrors[field] = message),
        );
        return setValidationMessages(formErrors);
      }

      if (onSuccess) onSuccess(payload);
    },
  });

  return {
    validationMessages,
    formState,
    isSubmitting,
    onChange,
    onSubmit,
  };
};

export default useCampusForm;
