/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useVersoAPI } from '../../hooks/useVersoAPI';
import GraphQLErrorCapture from '../GraphQLErrorCapture';
import Reflections from './Reflections';

const ReflectionsContainer = () => {
  const { cacheValue = {} } = useVersoAPI({
    loadOnMount: true,
    operation: {
      query: /* GraphQL */ `
        query Discoveries_currentTeacher {
          currentTeacher {
            fullName
          }
        }
      `,
    },
  });

  return (
    <GraphQLErrorCapture cacheValue={cacheValue}>
      <Reflections
        fullName={cacheValue.data.currentTeacher.fullName}
        loading={!cacheValue.data}
      />
    </GraphQLErrorCapture>
  );
};

export default ReflectionsContainer;
