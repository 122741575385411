/** @jsx jsx */
import PropTypes from 'prop-types';
import { useMemo, memo, useCallback, useState, useEffect, useRef } from 'react';
import { jsx, useThemeUI, Flex } from 'theme-ui';
import { ChartKeyItem, LineChart } from '@verso/components';
import ChartLoader from './ChartLoader';

const getActiveLineTooltipStubByFilter = filter => {
  switch (filter) {
    case 'research': {
      return 'viewed resources this week';
    }
    case 'activity': {
      return 'created an activity this week';
    }
    case 'feedback': {
      return 'received feedback this week';
    }
    case 'reflection': {
      return 'documented a reflection this week';
    }
    case 'discussion': {
      return 'commented on a reflection this week';
    }
  }
};

const subtractMonthsFromDate = (dateObj, quantity) => {
  // console.log(dateObj);
  const newDate = new Date(dateObj);
  const newMonth = newDate.getMonth() - quantity;
  newDate.setMonth(newMonth);
  return newDate;
};

const EngagementLineChart = ({
  chartId,
  data,
  activeFilter,
  loading,
  // zoomRange: zoomRangeExternal,
  // onChangeZoomRange: onChangeZoomRangeExternal,
  ...props
}) => {
  const context = useThemeUI();
  const themeRef = useRef(context.theme);

  const [zoomRange, setZoomRange] = useState([]);
  const [zoomRangeStore, setZoomRangeStore] = useState([]);
  // console.log('zoomRange', zoomRange);

  // XXX Janky. Issue is that range of the chart can pan within the one
  // date. By syncing external range with internal using on dates we keep
  // repositioning the range into the center of a new date when we pan into it
  // using the scrollbar. This causes the charts range to jump from the correct position
  // matching the scrollbar position to the external synced date which jumps the range
  // to the center of the date and then back to the scrollbar position again.

  // Fix would be initing chart to a specific range date as a separate thing and then
  // sync range using the range start and end values rather then fetching the data item.
  const onChangeZoomRange = useCallback(
    newZoomRange => {
      const storedZoomRange = zoomRangeStore.find(
        item => item.axisId === newZoomRange.axisId,
      );
      if (storedZoomRange) {
        if (
          newZoomRange.start === storedZoomRange.start &&
          newZoomRange.end === storedZoomRange.end
        ) {
          return;
        }

        setZoomRangeStore(oldZoomArray => {
          const newZoomArray = [...oldZoomArray];

          const zoomObjIndex = newZoomArray.findIndex(
            item => item.axisId === newZoomRange.axisId,
          );

          newZoomArray[zoomObjIndex] = newZoomRange;
          newZoomArray.source = 'component';

          return newZoomArray;
        });
      } else {
        setZoomRangeStore(oldZoomArray => {
          const newZoomArray = [...oldZoomArray];

          newZoomArray.push(newZoomRange);

          return newZoomArray;
        });
      }
    },
    [zoomRangeStore],
  );

  // Sets initial zoom range when data loads in.
  useEffect(() => {
    if (data.length > 0 && zoomRange.length === 0) {
      // If we have a zoom range stored, use that otherwise use custom range.
      setZoomRange(
        zoomRangeStore.length > 0
          ? zoomRangeStore
          : [
              {
                axisId: 'id-x-axes-date',
                start: subtractMonthsFromDate(
                  data[data.length - 1].date,
                  3,
                ).toISOString(),
                end: data[data.length - 1].date,
                source: 'local',
              },
            ],
      );
    }
  }, [data, onChangeZoomRange, zoomRange.length, zoomRangeStore]);

  // Updated data array to custom tooltip text.
  const mutatedData = useMemo(() => {
    return data.map(dataItem => {
      const newDataItem = { ...dataItem };

      const lineAllAmountAsString = `${dataItem.lineIdAll} teacher${
        dataItem.lineIdActive === 1 ? '' : 's'
      }`;
      const lineActiveAmountAsString = `${dataItem.lineIdActive} teacher${
        dataItem.lineIdActive === 1 ? '' : 's'
      }`;

      newDataItem.lineAllTooltip = `${lineAllAmountAsString} with accounts`;
      newDataItem.lineActiveTooltip = newDataItem.lineActiveTooltip = `${lineActiveAmountAsString} ${getActiveLineTooltipStubByFilter(
        activeFilter,
      )}`;
      return newDataItem;
    });
  }, [activeFilter, data]);

  const config = useMemo(() => {
    const theme = themeRef.current;
    return {
      scrollbar: {
        enabled: true,
        xAxis: {
          enabled: true,
          axisId: 'id-x-axes-date',
        },
        yAxis: {
          enabled: false,
        },
      },
      cursor: {
        enabled: true,
        behavior: 'none',
        xAxisId: 'id-x-axes-date',
        lineY: {
          enabled: false,
        },
        lineX: {
          enabled: true,
          fullWidth: true,
          strokeOpacity: 0,
        },
      },
      axes: {
        x: [
          {
            id: 'id-x-axes-date',
            type: 'date',
            strokeColor: theme.colors.neutral[1],
            oppositeSide: false,
            showGrid: false,
            tooltipEnabled: false,
            showLabels: true,
            minGridDistance: 48,
            startLocation: 0.5,
            endLocation: 0.5,
            // Will maintain zoom level over data changes, assuming the entire chart is rerendered.
            keepSelection: true,
          },
        ],
        y: [
          {
            id: 'id-y-axes-value',
            type: 'value',
            strokeColor: theme.colors.neutral[1],
            oppositeSide: false,
            showGrid: false,
            tooltipEnabled: false,
            minGridDistance: 32,
            min: 0,
          },
        ],
      },
      lines: [
        {
          id: 'id-line-all',
          xAxisId: 'id-x-axes-date',
          yAxisId: 'id-y-axes-value',
          yAxisDataId: 'lineIdAll',
          xAxisDataId: 'date',
          name: 'All teachers',
          color: theme.colors.secondary,
          tooltipText: '{lineAllTooltip}',
          tooltipWrap: false,
          strokeWidth: 4,
        },
        {
          id: 'id-line-active',
          xAxisId: 'id-x-axes-date',
          yAxisId: 'id-y-axes-value',
          yAxisDataId: 'lineIdActive',
          xAxisDataId: 'date',
          name: 'Active teachers',
          color: theme.colors.charts.data[3],
          tooltipText: `{lineActiveTooltip}`,
          tooltipWrap: false,
          strokeWidth: 4,
        },
      ],
    };
  }, []);

  return (
    <div {...props}>
      <div sx={{ position: 'relative' }}>
        <LineChart
          chartId={chartId}
          data={mutatedData}
          config={config}
          zoomRange={zoomRange}
          onChangeZoomRange={onChangeZoomRange}
          sx={{ height: 12 }}
        />
        {loading && <ChartLoader />}
      </div>
      <Flex sx={{ justifyContent: 'center', mb: 4 }}>
        <ChartKeyItem sx={{ mx: 3 }} color="secondary" variant="line">
          All teachers
        </ChartKeyItem>
        <ChartKeyItem sx={{ mx: 3 }} color="charts.data.3" variant="line">
          Active teachers
        </ChartKeyItem>
      </Flex>
    </div>
  );
};

export const EngagementLineChartPropTypes = {
  chartId: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
      ]),
      lineIdAll: PropTypes.number,
      lineIdActive: PropTypes.number,
    }),
  ),
  loading: PropTypes.bool,
  activeFilter: PropTypes.string,
};

EngagementLineChart.propTypes = EngagementLineChartPropTypes;

EngagementLineChart.defaultProps = {
  chartId: 'engagement-line-chart',
  data: [],
  loading: false,
  activeFilter: 'research',
};

export default memo(EngagementLineChart);
