export default [
  {
    label: 'Terms',
    url: 'https://versolearning.com/terms-of-use-privacy-policy/',
  },
  {
    label: 'Privacy',
    url: 'https://versolearning.com/terms-of-use-privacy-policy/#privacy',
  },
  {
    label: 'About',
    url: 'https://versolearning.com/',
  },
  {
    label: 'Help',
    url: 'https://support.versoapp.com',
  },
];
