/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { ChipFilter } from '@verso/components';

const EngagementFilters = ({ activeFilter, onFilterSelect, ...props }) => {
  return (
    <Flex
      {...props}
      sx={{
        justifyContent: 'center',
        width: '100%',
        flexWrap: 'wrap',
        px: [0, 4],
      }}
    >
      <div sx={{ mb: 2, px: 2, flexBasis: '20%' }}>
        <ChipFilter
          variant="primary"
          onClick={() => onFilterSelect('research')}
          active={activeFilter === 'research'}
          sx={{ width: '100%', py: 0, height: 3 }}
        >
          Research
        </ChipFilter>
      </div>
      <div sx={{ mb: 2, px: 2, flexBasis: '20%' }}>
        <ChipFilter
          variant="primary"
          onClick={() => onFilterSelect('activity')}
          active={activeFilter === 'activity'}
          sx={{ width: '100%', py: 0, height: 3 }}
        >
          Activity
        </ChipFilter>
      </div>
      <div sx={{ mb: 2, px: 2, flexBasis: '20%' }}>
        <ChipFilter
          variant="primary"
          onClick={() => onFilterSelect('feedback')}
          active={activeFilter === 'feedback'}
          sx={{ width: '100%', py: 0, height: 3 }}
        >
          Feedback
        </ChipFilter>
      </div>
      <div sx={{ mb: 2, px: 2, flexBasis: '20%' }}>
        <ChipFilter
          variant="primary"
          onClick={() => onFilterSelect('reflection')}
          active={activeFilter === 'reflection'}
          sx={{ width: '100%', py: 0, height: 3 }}
        >
          Reflection
        </ChipFilter>
      </div>
      <div sx={{ mb: 2, px: 2, flexBasis: '20%' }}>
        <ChipFilter
          variant="primary"
          onClick={() => onFilterSelect('discussion')}
          active={activeFilter === 'discussion'}
          sx={{ width: '100%', py: 0, height: 3 }}
        >
          Discussion
        </ChipFilter>
      </div>
    </Flex>
  );
};

EngagementFilters.propTypes = {
  onFilterSelect: PropTypes.func.isRequired,
  activeFilter: PropTypes.oneOf([
    'research',
    'feedback',
    'reflection',
    'discussion',
    'activity',
  ]),
};

EngagementFilters.defaultProps = {
  activeFilter: 'research',
};

export default EngagementFilters;
