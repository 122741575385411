/** @jsx jsx */
/* eslint-disable react/prop-types */
import { jsx } from 'theme-ui';
import { useEffect, useMemo, useState } from 'react';
import { useQueryParams, ArrayParam, StringParam } from 'use-query-params';
import { useForm } from '@verso/components';
import mixpanel from 'mixpanel-browser';
import { useVersoAPI } from '../hooks/useVersoAPI';
import { get } from '../lib/utils';
import useDebounce from '../hooks/useDebounce';
import Filters from './Filters';
import GraphQLErrorCapture from './GraphQLErrorCapture';

const getChartFocusTooltipText = ({ id, label, count }) => {
  if (id === 'none') {
    return `${count} class${count !== 1 ? 'es do' : ' does'} not have a focus`;
  } else {
    return `${count} class${
      count !== 1 ? 'es are focused on' : ' is focused on'
    } ${label.toLowerCase()}`;
  }
};

// Renders and handles the form
const FiltersContainer = ({
  focusFilterData,
  filterOptionsDomain,
  filterOptionsYear,
  loading,
  errors,
}) => {
  const [query, setQuery] = useQueryParams({
    view: StringParam,
    domains: ArrayParam,
    years: ArrayParam,
    focus: ArrayParam,
  });

  const mappedErrors = {};
  errors &&
    errors.forEach(error => {
      mappedErrors[error.path[error.path.length - 1]] = {
        message: error.message,
      };
    });

  const initialFormState = {
    domains: [],
    years: [],
    focus: [...focusFilterData.map(dataItem => dataItem.id)],
  };

  const [previousFormState, setPreviousFormState] = useState(initialFormState);
  const { formState, setFormState, onChange, onSubmit } = useForm({
    initialState: initialFormState,
    onSubmit() {
      let queryTags = [];
      Object.keys(formState).forEach(key => {
        if (key !== 'none')
          queryTags.push(...(formState[key] || []).map(item => item.label));
      });

      mixpanel.track('Set Global Filters', { Query: queryTags });

      setQuery(
        {
          domains:
            formState.domains && formState.domains.length > 0
              ? formState.domains.map(selected => selected.value)
              : [],
          years:
            formState.years && formState.years.length > 0
              ? formState.years.map(selected => selected.value)
              : [],
          // If no focuses set, pass a focus of 'none' to explicitly override
          // automatically displaying all focuses.
          focus: formState.focus,
        },
        'pushIn',
      );
    },
  });

  const debouncedValue = useDebounce(formState, 2000);
  useEffect(() => {
    if (JSON.stringify(previousFormState) === JSON.stringify(debouncedValue))
      return; //Do nothing if no change
    setPreviousFormState(debouncedValue);
    onSubmit();
  }, [debouncedValue, onSubmit, previousFormState]);

  useEffect(() => {
    setFormState({
      domains:
        query.domains && filterOptionsDomain.length !== 0
          ? query.domains.map(value =>
              filterOptionsDomain.find(option => option.value === value),
            )
          : [],
      years:
        query.years && filterOptionsYear.length !== 0
          ? query.years.map(value =>
              filterOptionsYear.find(option => option.value === value),
            )
          : [],
      // If no focuses defined in query - show all focuses. If focus of 'none' found
      // apply an empty array.
      focus:
        query.focus && focusFilterData.length !== 0
          ? query.focus
          : [...focusFilterData.map(dataItem => dataItem.id)],
    });
  }, [
    setFormState,
    filterOptionsDomain,
    filterOptionsYear,
    query.domains,
    query.years,
    query.focus,
    focusFilterData,
  ]);

  return (
    <Filters
      errors={mappedErrors}
      loading={loading}
      focusChartId="filters-chart"
      focusFilterData={focusFilterData}
      formState={formState}
      onChange={onChange}
      onSubmit={onSubmit}
      onReset={() => {
        setFormState(() => initialFormState);
        setQuery(
          { view: query.view, focus: [...initialFormState.focus] },
          'replace',
        );
      }}
      filterOptionsDomain={filterOptionsDomain}
      filterOptionsYear={filterOptionsYear}
      onClickChartFilter={selected =>
        setFormState(state => ({
          ...state,
          focus: state.focus.includes(selected)
            ? state.focus.filter(focus => focus !== selected)
            : [...state.focus, selected],
        }))
      }
    />
  );
};

// Loads data for the form fields
const FiltersContainerLoader = () => {
  const [query] = useQueryParams({
    domains: ArrayParam,
    years: ArrayParam,
    focus: ArrayParam,
  });

  const getSelectOptions = filterData => {
    const queryIds = [
      ...(query.focus || []),
      ...(query.domains || []),
      ...(query.years || []),
    ];

    const filterCounts = {};
    // Count instances of each subject and yearId based on current query filters
    [
      ...(filterData.focus || []),
      ...(filterData.domains || []),
      ...(filterData.years || []),
    ]
      .filter(data => queryIds.includes(data._id || data.id))
      .map(data => {
        return [...(data.yearIds || []), ...(data.subjectIds || [])];
      })
      .flat()
      .map(id => {
        filterCounts[id] = filterCounts[id] ? (filterCounts[id] += 1) : 1;
        return id;
      });

    const domains = filterData.domains.map(({ _id, name, count }) => {
      const resolvedCount =
        !!query.focus || !!query.years ? filterCounts[_id] || 0 : count;

      return {
        label: `${name} (${resolvedCount})`,
        value: _id,
        count: resolvedCount,
        isError: resolvedCount === 0,
      };
    });

    const years = filterData.years.map(({ _id, name, count }) => {
      const resolvedCount =
        !!query.focus || !!query.domains ? filterCounts[_id] || 0 : count;

      return {
        label: `${name} (${resolvedCount})`,
        value: _id,
        count: resolvedCount,
        isError: resolvedCount === 0,
      };
    });

    return { domains, years };
  };

  const { loading, cacheValue = {} } = useVersoAPI({
    loadOnMount: true,
    operation: {
      query: /* GraphQL */ `
        query Filters {
          currentTeacher {
            school {
              campusFiltersDomain {
                _id
                name
                count
                subjectIds
                yearIds
              }
              campusFiltersYear {
                _id
                name
                count
                subjectIds
                yearIds
              }
              campusFiltersFocus {
                _id
                name
                count
                subjectIds
                yearIds
              }
            }
          }
        }
      `,
    },
  });

  const focusFilterData = useMemo(() => {
    const campusFiltersFocus =
      get(cacheValue, 'data.currentTeacher.school.campusFiltersFocus') || [];

    return campusFiltersFocus.map(focus => ({
      id: focus._id,
      label: focus.name,
      value: focus.count,
      subjectIds: focus.subjectIds,
      yearIds: focus.yearIds,
      tooltipText: getChartFocusTooltipText({
        id: focus._id,
        count: focus.count,
        label: focus.name,
      }),
    }));
  }, [cacheValue]);

  const domainData =
    get(cacheValue, 'data.currentTeacher.school.campusFiltersDomain') || [];
  const yearData =
    get(cacheValue, 'data.currentTeacher.school.campusFiltersYear') || [];
  const selectOptions = getSelectOptions({
    focus: focusFilterData,
    domains: domainData,
    years: yearData,
  });
  return (
    <GraphQLErrorCapture cacheValue={cacheValue} hasOwnError>
      <FiltersContainer
        loading={loading}
        errors={cacheValue.graphQLErrors}
        focusFilterData={focusFilterData}
        filterOptionsDomain={selectOptions.domains || []}
        filterOptionsYear={selectOptions.years || []}
      />
    </GraphQLErrorCapture>
  );
};

export default FiltersContainerLoader;
