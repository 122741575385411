/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Paragraph, Button } from '@verso/components';
import PageSimpleTemplate from '../PageSimpleTemplate';
import ErrorContentTemplate from '../ErrorContentTemplate';

const Offline = ({ ...props }) => {
  return (
    <PageSimpleTemplate
      {...props}
      backgroundUrl="https://media.versoapp.com/Images/backgrounds/teacher-class.jpg"
    >
      <ErrorContentTemplate title="You appear to be offline.">
        <Paragraph>
          Please check your internet connection and try again.
        </Paragraph>
        <Button
          variant="outlined"
          sx={{
            borderColor: 'textWhite',
            color: 'textWhite',
            bg: 'transparent',
          }}
          onClick={() => {
            location.reload();
          }}
        >
          reconnect
        </Button>
      </ErrorContentTemplate>
    </PageSimpleTemplate>
  );
};

export default Offline;
