/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import useAuthentication from '../hooks/useAuthentication';
import PageTemplate from './PageTemplate';
import PageTemplateLoading from './PageTemplateLoading';
import PageError from './pages/PageError';

const PageTemplateContainer = ({ children, cacheValue, loading, ...props }) => {
  const { logout } = useAuthentication();

  return cacheValue.data ? (
    <PageTemplate
      userAvatarUrl={
        cacheValue.data.currentTeacher.profile.avatar ||
        'https://media.versoapp.com/Images/default-avatar.svg'
      }
      title={cacheValue.data.currentTeacher.profile.schoolName}
      onLogout={() => logout()}
      {...props}
    >
      {children}
    </PageTemplate>
  ) : loading ? (
    <PageTemplateLoading />
  ) : (
    <PageError />
  );
};

PageTemplateContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  cacheValue: PropTypes.shape({
    data: PropTypes.shape({
      currentTeacher: PropTypes.shape({
        profile: PropTypes.shape({
          schoolName: PropTypes.string,
          avatar: PropTypes.string,
        }),
      }),
    }),
  }),
  loading: PropTypes.bool,
};

export default PageTemplateContainer;
