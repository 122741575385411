/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Spinner } from '@verso/components';

const ChartLoader = props => (
  <Spinner
    {...props}
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  />
);

export default ChartLoader;
