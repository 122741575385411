/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CardContent, Paragraph } from '@verso/components';
import PropTypes from 'prop-types';
import { memo } from 'react';
import CommunityConnectionsChart, {
  CommunityConnectionsChartPropTypes,
} from '../CommunityConnectionsChart';

const Community = ({ communityChartData, loading }) => {
  return (
    <CardContent sx={{ mt: [3, 4, 4, 5] }}>
      <Paragraph sx={{ textAlign: 'center' }}>
        Who are your teachers collaborating with?
      </Paragraph>
      <CommunityConnectionsChart
        data={communityChartData}
        loading={loading}
        chartId={'community-connections-chart'}
      />
    </CardContent>
  );
};

Community.propTypes = {
  communityChartData: CommunityConnectionsChartPropTypes.data,
  loading: PropTypes.bool,
};

export default memo(Community);
