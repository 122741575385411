/** @jsx jsx */
import {
  Heading,
  Avatar,
  ContentWrap,
  Menu,
  MenuItem,
  TetherExpander,
  Obfuscator,
  Button,
} from '@verso/components';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { jsx, Flex } from 'theme-ui';
import IconSignOut from '@fortawesome/fontawesome-free/svgs/solid/sign-out-alt.svg';
import IconSlidersH from '@fortawesome/fontawesome-free/svgs/solid/sliders-h.svg';

const Header = ({
  title,
  avatarUrl,
  onLogout,
  onClickShowFilters,
  ...props
}) => {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  return (
    <header
      {...props}
      sx={{
        bg: 'secondaryLight',
        minHeight: 7,
        width: '100%',
        position: 'relative',

        '&:before': {
          content: "''",
          position: 'absolute',
          bg: 'inherit',
          width: '100%',
          height: 7,
          bottom: -6,
        },
      }}
    >
      <ContentWrap sx={{ minHeight: 'inherit', justifyContent: 'center' }}>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 'inherit',
            my: 2,
          }}
        >
          <Heading
            as="h1"
            variant="heading5"
            sx={{
              variant: ['textStyles.heading6', 'textStyles.heading5'],
              flexGrow: 1,
            }}
          >
            {title}
          </Heading>
          {/* Reuse searchbar from v2 */}
          <Flex sx={{ alignItems: 'center', flexShrink: 0 }}>
            <Button
              variant="text"
              icon={IconSlidersH}
              sx={{
                display: ['block', 'block', 'block', 'none'],
                px: 2,
                mr: 2,
              }}
              onClick={onClickShowFilters}
            >
              Filters
            </Button>
            <TetherExpander
              visible={isAccountMenuOpen}
              sourceAnchorCoord={{ x: '100%', y: '100%' }}
              transitionDuration={250}
              fitInViewport={false}
              menuOriginX="right"
              menuOriginY="top"
              sx={{ zIndex: 4, position: 'relative' }}
              tetherComponent={
                <Menu
                  sx={{
                    position: 'relative',
                    zIndex: 4,
                    boxShadow: 6,
                  }}
                >
                  <MenuItem icon={IconSignOut} onClick={() => onLogout()}>
                    Sign out
                  </MenuItem>
                </Menu>
              }
            >
              <button
                sx={{
                  cursor: 'pointer',
                  borderRadius: '50%',
                  transition: 'box-shadow 200ms ease',
                  ':hover': {
                    boxShadow: 2,
                  },
                }}
                aria-label="Account profile photo that opens a list of account options"
                aria-haspopup={true}
                onClick={() => setIsAccountMenuOpen(prevState => !prevState)}
              >
                <Avatar
                  src={avatarUrl}
                  size={3}
                  sx={{
                    borderColor: 'white',
                    borderWidth: 'regular',
                    borderStyle: 'solid',
                  }}
                />
              </button>
            </TetherExpander>
          </Flex>
        </Flex>
      </ContentWrap>
      <Obfuscator
        sx={{
          position: 'fixed',
          zIndex: 3,
          visibility: isAccountMenuOpen ? 'visible' : 'hidden',
          opacity: isAccountMenuOpen ? 1 : 0,
          transition: theme =>
            isAccountMenuOpen
              ? `visibility 250ms, opacity 250ms ${theme.easing.quickToSlow}`
              : `visibility 250ms, opacity 250ms ${theme.easing.slowToQuick}`,
        }}
        onClick={() => setIsAccountMenuOpen(false)}
      />
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  avatarUrl: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  onClickShowFilters: PropTypes.func,
};

Header.defaultProps = {
  title: null,
  avatarUrl: null,
};

export default Header;
