/** @jsx jsx */
import {
  Card,
  CardContent,
  Field,
  Paragraph,
  Label,
  Input,
  Button,
  Image,
  Text,
} from '@verso/components';
import PropTypes from 'prop-types';
import { jsx, Flex } from 'theme-ui';
import { useRef } from 'react';
import PageSimpleTemplate from '../PageSimpleTemplate';

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getRandomWelcomeStatement = () => {
  // Add more statements here to be randomly selected on render.
  const welcomeStatements = [
    {
      title: 'On a learning journey?',
      subtitle: 'Don’t stop believin’',
      link: 'https://www.youtube.com/watch?v=1k8craCGpgs',
    },
    {
      title: 'Searching for clarity?',
      subtitle: 'Lyn Sharratt has some big ideas',
      link: 'https://www.goodreads.com/book/show/42585451-clarity',
    },
    {
      title: 'Time in short supply?',
      subtitle: '[Feed]back to the future',
      link: 'http://www.columbia.edu/~mvp19/ETF/Feedback.pdf',
    },
  ];

  return welcomeStatements[
    randomIntFromInterval(0, welcomeStatements.length - 1)
  ];
};

const Login = ({
  onSubmit,
  validationMessages,
  formState,
  onChange,
  isSubmitting = false,
  ...props
}) => {
  const welcomeStatement = useRef(getRandomWelcomeStatement());

  return (
    <PageSimpleTemplate
      {...props}
      // TODO : Update image.
      backgroundUrl="https://media.versoapp.com/Images/backgrounds/teacher-class.jpg"
    >
      <Flex sx={{ justifyContent: 'center' }}>
        <Card
          sx={{
            width: ['100%', 12],
          }}
        >
          <CardContent>
            <Flex sx={{ width: '100%', justifyContent: 'center', mb: 3 }}>
              <Flex sx={{ maxWidth: '80%', justifyContent: 'center' }}>
                <Image
                  sx={{
                    maxHeight: '164px',
                  }}
                  // TODO : Update image.
                  src={'https://media.versoapp.com/Brand/Verso/Dark.svg'}
                  alt={`Verso Campus logo`}
                />
              </Flex>
            </Flex>
            <Paragraph sx={{ textAlign: 'center', mb: 6 }}>
              <Text sx={{ variant: 'textStyles.heading5', display: 'block' }}>
                {welcomeStatement.current.title}
              </Text>
              <a
                href={welcomeStatement.current.link}
                target="_blank"
                rel="noreferrer noopener"
                sx={{
                  color: 'alerts.information',
                  textDecoration: 'underline',
                }}
              >
                {welcomeStatement.current.subtitle}
              </a>
            </Paragraph>
            <form onSubmit={onSubmit} sx={{ maxWidth: 13 }}>
              <Field>
                <Label htmlFor="user">Username</Label>
                <Input
                  required
                  id="user"
                  validationMessage={validationMessages.user}
                  onChange={onChange}
                  value={formState.user}
                />
              </Field>

              <Field>
                <Label htmlFor="password">Password</Label>
                <Input
                  required
                  id="password"
                  type="password"
                  validationMessage={validationMessages.password}
                  onChange={onChange}
                  value={formState.password}
                />
              </Field>

              <Button loading={isSubmitting} sx={{ width: '100%' }}>
                Login
              </Button>
            </form>

            <Paragraph sx={{ variant: 'textStyles.caption', mt: 5 }}>
              We acknowledge the Traditional Owners of the land on which we
              develop Verso. We pay our respects to their Elders, past and
              present, and the Aboriginal Elders of other communities who may
              use our services.
            </Paragraph>
          </CardContent>
        </Card>
      </Flex>
    </PageSimpleTemplate>
  );
};

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validationMessages: PropTypes.shape({
    user: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  formState: PropTypes.shape({
    user: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default Login;
