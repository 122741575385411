/** @jsx jsx */
import { Global } from '@emotion/core';
import { jsx, css } from 'theme-ui';

const GlobalStyles = () => (
  <Global
    styles={css({
      button: {
        outline: 'none',
      },
    })}
  />
);

export default GlobalStyles;
