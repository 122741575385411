/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import {
  Drawer,
  DrawerPane,
  DrawerControls,
  DrawerContent,
  Footer,
  ContentWrap,
  useControlledState,
} from '@verso/components';
import PropTypes from 'prop-types';
import { useBreakpointIndex } from '@theme-ui/match-media';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import ContextComponents from '../lib/context-components';
import Header from './Header';
import footerLinks from './common/footer-links';

// TODO:
/* eslint-disable react/prop-types */
const PageTemplate = ({
  title,
  userAvatarUrl,
  onLogout,
  children,
  showFilters: externalShowFilters,
  onClickShowFilters,
  ...props
}) => {
  const [query] = useQueryParams({
    view: StringParam,
  });

  const { view } = query;

  const breakpointIndex = useBreakpointIndex();

  const [showFilters, setShowFilters] = useControlledState(
    externalShowFilters,
    onClickShowFilters,
  );

  useEffect(() => {
    if (showFilters) mixpanel.track('Open Filters Drawer', { View: view });
  }, [showFilters, view]);
  return (
    <Flex
      {...props}
      sx={{ bg: 'neutral.0', minHeight: '100vh', flexDirection: 'column' }}
    >
      <Header
        avatarUrl={userAvatarUrl}
        title={title}
        onLogout={onLogout}
        onClickShowFilters={() => setShowFilters(prevState => !prevState)}
      />
      <ContentWrap sx={{ flex: '1 1 100%', mb: 6 }}>{children}</ContentWrap>
      <Footer
        logoAlt="Verso"
        logoUrl="https://media.versoapp.com/Brand/Verso/Light.svg"
        links={footerLinks}
      />
      {breakpointIndex < 3 && (
        <ContextComponents.Consumer>
          {({ Filters }) => (
            <Drawer
              position="right"
              visible={showFilters}
              onClickOutside={() => setShowFilters(false)}
            >
              <DrawerPane>
                <DrawerControls onClose={() => setShowFilters(false)} />
                <DrawerContent>
                  <Filters />
                </DrawerContent>
              </DrawerPane>
            </Drawer>
          )}
        </ContextComponents.Consumer>
      )}
    </Flex>
  );
};

PageTemplate.propTypes = {
  onLogout: PropTypes.func,
  userAvatarUrl: PropTypes.string,
  title: PropTypes.string,
  showFilters: PropTypes.bool,
  onClickShowFilters: PropTypes.func,
  children: PropTypes.node,
};

PageTemplate.defaultProps = {
  showFilters: false,
  onClickShowFilters: null,
};
export default PageTemplate;
