/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from 'theme-ui';
import { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import useAuthentication from '../hooks/useAuthentication';
import ContextComponents from '../lib/context-components';
import { useVersoAPI } from '../hooks/useVersoAPI';
import { get } from '../lib/utils';
import Offline from './pages/Offline';
import LoginContainer from './pages/LoginContainer';
import Filters from './FiltersContainer';
import AppNav from './AppNavContainer';
import PageTemplate from './PageTemplateContainer';
import ContentTemplate from './ContentTemplate';
import Unauthorised from './pages/Unauthorised';

const contextComponents = {
  Filters,
  AppNav,
  PageTemplate,
};

const LayoutContainer = ({ as: C, ...props }) => {
  const { logout, signedIn, setSignedIn } = useAuthentication();

  const [isOnline, setIsOnline] = useState(true);

  const { loading, cacheValue = {} } = useVersoAPI({
    loadOnMount: true,
    operation: {
      query: /* GraphQL */ `
        query PageTemplate_currentTeacher {
          currentTeacher {
            _id
            leader
            emails {
              address
            }
            profile {
              avatar
              schoolName
              firstName
              lastName
            }
          }
        }
      `,
    },
  });

  useEffect(() => {
    const teacher = get(cacheValue, 'data.currentTeacher');
    if (teacher) {
      mixpanel.identify(teacher._id);
      mixpanel.people.set({
        $first_name: teacher.profile.firstName,
        $last_name: teacher.profile.lastName,
        $email: teacher.emails[0].address,
        School_name: teacher.profile.schoolName,
      });
    }
  });

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);

    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  /* Page Layouts */
  if (!isOnline) return <Offline />;

  if (signedIn && cacheValue.data && !cacheValue.data.currentTeacher.leader) {
    return <Unauthorised onClickLogout={() => logout()} />;
  }

  if (signedIn) {
    return (
      <ContextComponents.Provider value={contextComponents}>
        <PageTemplate cacheValue={cacheValue} loading={loading}>
          <ContentTemplate>
            <C {...props} />
          </ContentTemplate>
        </PageTemplate>
      </ContextComponents.Provider>
    );
  }

  return (
    <LoginContainer
      onLogin={() => {
        setSignedIn(true);
      }}
    />
  );
};

LayoutContainer.propTypes = {
  as: PropTypes.func.isRequired,
};

export default LayoutContainer;
