/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useQueryParams, StringParam } from 'use-query-params';
import AppNav from './AppNav';

const AppNavContainer = () => {
  const [query, setQuery] = useQueryParams({
    view: StringParam,
  });

  const { view = 'engagement' } = query;

  // TODO newActivityCount should contain the number of new activity items from the
  // reflections activity feed.
  const newActivityCount = undefined;

  // TODO query etc,
  return (
    <AppNav
      selectedItemId={view}
      newActivityCount={newActivityCount}
      onClickItem={id => {
        setQuery({ view: id }, 'pushIn');
      }}
    />
  );
};

export default AppNavContainer;
