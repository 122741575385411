/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Paragraph, Button } from '@verso/components';
import { navigate } from '@reach/router';
import PageSimpleTemplate from '../PageSimpleTemplate';
import ErrorContentTemplate from '../ErrorContentTemplate';

const PageNotFound = ({ ...props }) => {
  return (
    <PageSimpleTemplate
      {...props}
      backgroundUrl="https://media.versoapp.com/Images/backgrounds/teacher-class.jpg"
    >
      <ErrorContentTemplate title="Sorry, we couldn’t find the page you’re looking for.">
        <Paragraph>
          Try accessing it via the dashboard, otherwise please{' '}
          <a
            href="https://support.versoapp.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ textDecoration: 'underline', color: 'textWhite' }}
          >
            get in touch
          </a>{' '}
          if we can be of assistance.
        </Paragraph>
        <Button
          variant="outlined"
          sx={{
            borderColor: 'textWhite',
            color: 'textWhite',
            bg: 'transparent',
          }}
          onClick={() => {
            navigate('/');
          }}
        >
          Go to dashboard
        </Button>
      </ErrorContentTemplate>
    </PageSimpleTemplate>
  );
};

export default PageNotFound;
