/** @jsx jsx */
import {
  Field,
  Button,
  Text,
  Label,
  SelectEnhanced,
  FilterTreeMapChart,
} from '@verso/components';
import PropTypes from 'prop-types';
import { useCallback, memo, useMemo } from 'react';
import { jsx, Flex } from 'theme-ui';
import ChartLoader from './ChartLoader';
import SectionError from './SectionError';

const Filters = ({
  focusChartId,
  focusFilterData,
  onClickChartFilter,
  filterOptionsDomain,
  filterOptionsYear,
  onChange,
  formState,
  onSubmit,
  onReset,
  loading,
  errors,
  ...props
}) => {
  // Combine focusFilterData with the selected options from formState.focus.
  const focusFilterDataWithSelection = useMemo(() => {
    const newData = focusFilterData.map(dataItem => {
      const newDataItem = { ...dataItem };
      if (formState.focus.indexOf(dataItem.id) >= 0) {
        newDataItem.active = true;
      }
      return newDataItem;
    });
    return newData;
  }, [focusFilterData, formState.focus]);

  const handleOnClickFocusChartFilter = useCallback(
    value => {
      onClickChartFilter(value);
    },
    [onClickChartFilter],
  );

  const filtersTreeMapChartConfig = useMemo(() => {
    return {
      tooltipText: '{tooltipText}',
    };
  }, []);

  return (
    <div {...props} sx={{ width: '100%' }}>
      <form onSubmit={onSubmit}>
        <Text
          sx={{
            display: 'block',
            borderBottomStyle: 'solid',
            borderBottomWidth: 'thin',
            borderBottomColor: 'neutral.1',
            pb: 1,
            mb: 2,
          }}
        >
          Professional focus
        </Text>
        {errors.campusFiltersFocus ? (
          <SectionError title="This filter is currently unavailable" />
        ) : (
          <div sx={{ position: 'relative' }}>
            <FilterTreeMapChart
              chartId={focusChartId}
              onClickFilter={handleOnClickFocusChartFilter}
              data={focusFilterDataWithSelection}
              config={filtersTreeMapChartConfig}
            />
            {loading && <ChartLoader />}
          </div>
        )}

        <Field sx={{ position: 'relative', mt: 3 }}>
          <Label
            htmlFor="domains"
            sx={{
              pb: 1,
              mb: 1,
            }}
          >
            Subject or domain
          </Label>
          <SelectEnhanced
            id="domains"
            isMulti
            placeholder="Type to search subjects"
            onChange={onChange}
            value={formState.domains}
            options={filterOptionsDomain}
            isLoading={loading}
          />
        </Field>

        <Field sx={{ position: 'relative', mb: 5 }}>
          <Label
            htmlFor="years"
            sx={{
              pb: 1,
              mb: 1,
            }}
          >
            Year level
          </Label>
          <SelectEnhanced
            id="years"
            isMulti
            placeholder="Type to search year levels"
            onChange={onChange}
            value={formState.years}
            options={filterOptionsYear}
            isLoading={loading}
            noOptionsMessage={() => {
              return 'No results found';
            }}
          />
        </Field>

        <Flex>
          <Button
            type="button"
            variant="text"
            sx={{ mr: 3 }}
            onClick={() => onReset()}
          >
            Clear filters
          </Button>
        </Flex>
      </form>
    </div>
  );
};

Filters.propTypes = {
  focusChartId: PropTypes.string.isRequired,
  focusFilterData: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onClickChartFilter: PropTypes.func,
  filterOptionsDomain: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  filterOptionsYear: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  formState: PropTypes.shape({
    focus: PropTypes.arrayOf(PropTypes.string),
    domains: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
    years: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  }),
  loading: PropTypes.bool,
  errors: PropTypes.shape({ campusFiltersFocus: PropTypes.shape({}) }),
};

Filters.defaultProps = {
  loading: false,
  filterOptionsDomain: [],
  filterOptionsYear: [],
  formState: {},
  focusFilterData: [],
  errors: {},
};
export default memo(Filters);
