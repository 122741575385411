/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { CardContent, Paragraph } from '@verso/components';
import { memo } from 'react';
import ImpactLearningLineChart, {
  ImpactLearningLineChartPropTypes,
} from '../ImpactLearningLineChart';
import ImpactFeelingLineChart, {
  ImpactFeelingLineChartPropTypes,
} from '../ImpactFeelingLineChart';

const Impact = ({
  learningChartData,
  feelingChartData,
  loading,
  learningChartTimeFilter,
  feelingChartTimeFilter,
  onSelectLearningChartTimeFilter,
  onSelectFeelingChartTimeFilter,
}) => {
  return (
    <CardContent sx={{ mt: [3, 4, 4, 5] }}>
      <Paragraph sx={{ textAlign: 'center' }}>
        What impact are teachers having on student learning?
      </Paragraph>
      <ImpactLearningLineChart
        data={learningChartData}
        timeFilter={learningChartTimeFilter}
        onSelectTimeFilter={onSelectLearningChartTimeFilter}
        loading={loading}
      />
      <Paragraph sx={{ textAlign: 'center' }}>
        How are students feeling about their learning?
      </Paragraph>
      <ImpactFeelingLineChart
        data={feelingChartData}
        timeFilter={feelingChartTimeFilter}
        onSelectTimeFilter={onSelectFeelingChartTimeFilter}
        loading={loading}
      />
    </CardContent>
  );
};

Impact.propTypes = {
  learningChartData: ImpactLearningLineChartPropTypes.data,
  feelingChartData: ImpactFeelingLineChartPropTypes.data,
  loading: PropTypes.bool,
  learningChartTimeFilter: ImpactLearningLineChartPropTypes.timeFilter,
  feelingChartTimeFilter: ImpactFeelingLineChartPropTypes.timeFilter,
  onSelectLearningChartTimeFilter:
    ImpactLearningLineChartPropTypes.onSelectTimeFilter,
  onSelectFeelingChartTimeFilter:
    ImpactFeelingLineChartPropTypes.onSelectTimeFilter,
};

export default memo(Impact);
