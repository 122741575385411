import React, { createContext, useState, useContext } from 'react';
import { isPast } from 'date-fns';
import PropTypes from 'prop-types';
import { GraphQLContext } from 'graphql-react';
import { versoFetchOptionsOverride } from './graphql';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const graphql = useContext(GraphQLContext);

  const [state, setState] = useState(() => {
    const signedIn = () => {
      const date = localStorage.getItem('Meteor.loginTokenExpires');
      if (date && isPast(new Date(date))) {
        // expired
        return false;
      }
      return !!localStorage.getItem('Meteor.loginToken');
    };

    return {
      setSignedIn(value) {
        setState(state => ({ ...state, signedIn: value }));
      },
      signedIn: signedIn(),
      logout() {
        setState(state => ({ ...state, signedIn: false }));

        localStorage.removeItem('Meteor.loginToken');
        localStorage.removeItem('Meteor.loginTokenExpires');

        graphql.operate({
          fetchOptionsOverride: versoFetchOptionsOverride,
          resetOnLoad: true,
          operation: {
            query: /* GraphQL */ `
              mutation logout {
                logout {
                  success
                }
              }
            `,
          },
        });
      },
    };
  });

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
