/** @jsx jsx */
import PropTypes from 'prop-types';
import { useMemo, memo, useState, useRef } from 'react';
import { jsx, useThemeUI, Flex } from 'theme-ui';
import {
  ChartKeyItem,
  Text,
  TetherExpander,
  Menu,
  MenuItem,
  Icon,
  useOnClickOutside,
  useControlledState,
  LineChart,
} from '@verso/components';
import IconCaretDown from '@fortawesome/fontawesome-free/svgs/solid/caret-down.svg';
import ChartLoader from './ChartLoader';

const ImpactLearningLineChart = ({
  chartId,
  data,
  timeFilter: timeFilterExternal,
  onSelectTimeFilter,
  loading,
  ...props
}) => {
  const context = useThemeUI();
  const { theme } = context;

  const timeFilterDisplay = {
    14: '2 weeks',
    31: '1 month',
    91: '3 months',
    182: '6 months',
    365: '1 year',
  };

  const timeFilterRef = useRef();
  const [openTimeMenu, setOpenTimeMenu] = useState(false);
  const [timeFilter, setTimeFilter] = useControlledState(
    timeFilterExternal,
    onSelectTimeFilter,
  );

  useOnClickOutside(timeFilterRef, () => setOpenTimeMenu(false));

  const config = useMemo(
    () => ({
      cursor: {
        enabled: true,
        behavior: 'none',
        lineY: {
          enabled: false,
        },
        lineX: {
          enabled: false,
        },
      },
      axes: {
        x: [
          {
            type: 'date',
            strokeColor: theme.colors.neutral[1],
            oppositeSide: false,
            showGrid: false,
            tooltipEnabled: false,
            showLabels: false,
            startLocation: 0.5,
            endLocation: 0.5,
          },
        ],
        y: [
          {
            type: 'value',
            title: 'STUDENTS',
            strokeColor: theme.colors.neutral[1],
            oppositeSide: false,
            showGrid: false,
            tooltipEnabled: false,
            min: 0,
          },
        ],
      },
      lines: [
        {
          yAxisDataId: 'lineId1',
          xAxisDataId: 'date',
          name: 'All teachers',
          tooltipText: '{valueY} teachers',
          strokeWidth: 4,
          baseAxis: 'x',
          fillOpacity: 1,
          color: theme.colors.charts.traffic[0],
          fill: theme.colors.charts.traffic[0],
        },
        {
          yAxisDataId: 'lineId2',
          xAxisDataId: 'date',
          name: 'Active teachers',
          tooltipText: '{valueY} teachers',
          strokeWidth: 4,
          baseAxis: 'x',
          fillOpacity: 1,
          color: theme.colors.charts.traffic[1],
          fill: theme.colors.charts.traffic[1],
        },
        {
          yAxisDataId: 'lineId3',
          xAxisDataId: 'date',
          name: 'Active teachers',
          tooltipText: '{valueY} teachers',
          strokeWidth: 4,
          baseAxis: 'x',
          fillOpacity: 1,
          color: theme.colors.charts.traffic[2],
          fill: theme.colors.charts.traffic[2],
        },
      ],
    }),
    [theme.colors.charts.traffic, theme.colors.neutral],
  );

  return (
    <div {...props}>
      <div sx={{ position: 'relative' }}>
        <LineChart
          chartId={chartId}
          data={data}
          config={config}
          sx={{ height: 11 }}
        />
        {loading && <ChartLoader sx={{ top: '45%' }} />}
        <div
          sx={{
            position: 'absolute',
            bottom: 4,
            left: theme => theme.sizes[2] + theme.sizes[2],
          }}
        >
          <TetherExpander
            visible={openTimeMenu}
            sourceAnchorCoord={{ x: '0', y: '100%' }}
            transitionDuration={250}
            fitInViewport={false}
            menuOriginX="left"
            menuOriginY="top"
            tetherComponent={
              <Menu
                sx={{
                  position: 'relative',
                  zIndex: 4,
                  boxShadow: 6,
                }}
              >
                <MenuItem onClick={() => setTimeFilter(14)}>
                  2 weeks ago
                </MenuItem>
                <MenuItem onClick={() => setTimeFilter(31)}>
                  1 month ago
                </MenuItem>
                <MenuItem onClick={() => setTimeFilter(91)}>
                  3 months ago
                </MenuItem>
                <MenuItem onClick={() => setTimeFilter(182)}>
                  6 months ago
                </MenuItem>
                <MenuItem onClick={() => setTimeFilter(365)}>
                  1 year ago
                </MenuItem>
              </Menu>
            }
          >
            <button
              ref={timeFilterRef}
              sx={{
                display: 'flex',
                alignItems: 'center',
                pl: 2,
              }}
              onClick={() => setOpenTimeMenu(prevState => !prevState)}
            >
              <Text variant="caption" sx={{ color: 'textMediumEmphasis' }}>
                {timeFilterDisplay[timeFilter]} ago
              </Text>
              <Icon icon={IconCaretDown} />
            </button>
          </TetherExpander>
        </div>
        <div sx={{ position: 'absolute', bottom: 4, right: 0 }}>
          <Text variant="caption" sx={{ color: 'textMediumEmphasis' }}>
            Today
          </Text>
        </div>
      </div>
      <Flex sx={{ justifyContent: 'center', mb: 5 }}>
        <ChartKeyItem sx={{ mx: 3 }} color="charts.traffic.0">
          Got it
        </ChartKeyItem>
        <ChartKeyItem sx={{ mx: 3 }} color="charts.traffic.1">
          Almost there
        </ChartKeyItem>
        <ChartKeyItem sx={{ mx: 3 }} color="charts.traffic.2">
          Confused
        </ChartKeyItem>
      </Flex>
    </div>
  );
};

export const ImpactLearningLineChartPropTypes = {
  chartId: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
      ]),
      lineId1: PropTypes.number,
      lineId2: PropTypes.number,
      lineId3: PropTypes.number,
    }),
  ),
  loading: PropTypes.bool,
  timeFilter: PropTypes.oneOf([14, 31, 91, 182, 365]),
  onSelectTimeFilter: PropTypes.func.isRequired,
};

ImpactLearningLineChart.propTypes = ImpactLearningLineChartPropTypes;

ImpactLearningLineChart.defaultProps = {
  chartId: 'impact-learning-line-chart',
  data: null,
  loading: false,
  timeFilter: 365,
};

export default memo(ImpactLearningLineChart);
